<template>
  <el-dialog
    v-model="dialogVisible"
    title="拒绝原因"
    :close-on-click-modal="false"
    width="550px"
  >
    <div class="confirmbox mb-20">
      <el-input
        v-model="state.textarea"
        :autosize="{ minRows: 5, maxRows: 5 }"
        type="textarea"
        placeholder="请填写拒绝原因…"
        resize="none"
      />
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton
          class="searcML"
          width="110"
          height="40"
          title="确定"
          CSStype="2"
          @buttonclick="submit()"
          v-model:loading="dialogloading"
        ></oabutton>
        <oabutton
          class="searcML"
          width="110"
          height="40"
          title="取消"
          CSStype="3"
          @buttonclick="dialogVisible = false"
        >
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from "vue";
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
const dialogloading = ref(false); // 弹框按钮loading 显示
const dialogVisible = ref(false); //弹框显示
const emit = defineEmits(["Emit"]);
const id = ref(0); //操作的id
const state = reactive({
  textarea: "",
  id: "",
});

const getData = (data) => {
  dialogVisible.value = true;
  console.log(data);
  state.id = data;
};
// 数据提交
const submit = () => {
  dialogloading.value = true;
  httpToken({
    method: "post",
    url: "/seal/seal-admin/examine",
    data: qs.stringify({ id: state.id, type: 2 }),
  })
    .then((res) => {
      emit("Emit");
      dialogVisible.value = false;
      dialogloading.value = false;
      handleMessage("拒绝成功", "sucess");
      
    })
    .catch((e) => {
      dialogloading.value = false;
    });
};
defineExpose({
  getData,
});
</script>
<style lang="scss" scoped >
.confirmbox {
  ::v-deep .el-textarea__inner {
    background-color: rgba(248, 248, 248, 1);
  }
}
</style>